.app-nav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 8px;
}

.app-nav .custom-switch {
  margin-left: 10px;
}

.App {
  max-width: 960px;
  margin: 0 auto 20px;
}

.markdown-content {
  padding: 30px 1.5em 0;
  white-space: pre-wrap;
  font-weight: 400;
}

.loader-content {
  text-align: center;
  margin-top: 15px;
}

@media print {
  .App {
    max-width: 100%;
  }

  .app-nav {
    display: none;
  }
}
